/* istanbul ignore file */
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { graphql } from "gatsby";
import React from "react";
import { DisclaimerMessageDataFragment } from "../../../graphql-types";
import { RichTextRenderer } from "../RichTextRenderer";

type Props = DisclaimerMessageDataFragment;

export default function DisclaimerMessage({ text }: Props) {
  return (
    <div className="px-4">
      <div
        className="max-w-lg mx-auto flex gap-4 p-4 text-sm text-red-800 rounded-lg bg-red-50 items-start"
        role="alert"
      >
        <ExclamationTriangleIcon className="w-6 h-auto shrink-0" />
        {text?.raw && (
          <RichTextRenderer
            className="inline-html"
            component="div"
            raw={text.raw}
          />
        )}
      </div>
    </div>
  );
}

export const fragment = graphql`
  fragment DisclaimerMessageData on DisclaimerMessage {
    text {
      raw
    }
  }
`;
